import React from "react";
import Layout from "../layouts";
import { toTitleCase, replaceAll } from "../utils/common";
import SheetsTab from "../components/integration/google-sheet/tab";
import SheetsGrid from "../components/integration/sheetGrid";
import PlatformsNavigation from "../components/integration/platform-navigation";
import { Helmet } from "react-helmet";

export default class IntegrationSupportedFieldList extends React.Component {
    constructor(props) {
        super(props);
        const { platformName, mappings, allPlatformsNames, viewsData, platformDisplayNameSetting } = props.pageContext;

        const platformDisplayName = (platformDisplayNameSetting[platformName] || toTitleCase(replaceAll(platformName, '_', ' '))) + " Supported Fields";
        const allMetrics = Object.keys(viewsData);
        let numbersOfDimensions = 0;
        let numberOfMetrics = 0;
        let numberOfCalculatedFields = 0;
        let spreadsheet = [];
        allMetrics.forEach((metric) => {
            const metricData = viewsData[metric];
            var sheetData = [];
            if (metricData) {
                metricData.forEach((viewData) => {
                    if (mappings.hasOwnProperty(viewData)) {
                        const map = mappings[viewData];
                        if (map.hasOwnProperty("valueDerivation"))
                            numberOfCalculatedFields += 1;
                        else if (map["type"] === "dimension") {
                            numbersOfDimensions += 1;
                        }
                        else if (map["type"] === "metric") {
                            numberOfMetrics += 1;
                        }

                        var newSheetData = {};
                        newSheetData["fieldLabel"] = map["label"];
                        newSheetData["type"] = map["type"];
                        newSheetData["outputFormat"] = map["format"];
                        newSheetData["fieldName"] = "";
                        newSheetData["nugitKey"] = map["key"];
                        newSheetData["combinesWith"] = "";
                        var nugiTables = [];
                        allMetrics.forEach((m) => {
                            viewsData[m].forEach((v) => {
                                if (v === viewData) {
                                    nugiTables.push(this.getMetricName(m));
                                    return;
                                }
                            });
                        });
                        newSheetData["nugitTables"] = nugiTables.join(", ");
                        const metricMapping = map.mapping;
                        if (metricMapping) {
                            var combinesWithName = Object.keys(metricMapping);
                            newSheetData["combinesWith"] = combinesWithName.map(n => {
                                if (allPlatformsNames.includes(n))
                                    return platformDisplayNameSetting[n] || this.getMetricName(n);
                                return "";
                            }).filter(n => n !== '').sort((a, b) => a.localeCompare(b)).join(", ");
                            if (combinesWithName.length > 0) {
                                if (metricMapping[combinesWithName[0]].hasOwnProperty("storageKey"))
                                    newSheetData["fieldName"] = metricMapping[combinesWithName[0]]["storageKey"];
                            }
                        }
                        sheetData.push(newSheetData);
                    }
                });
            }
            let sheet = {};
            sheet["properties"] = {};
            sheet["properties"]["title"] = this.getMetricName(metric);
            sheet["properties"]["sheetId"] = `${metric}`;
            sheet["data"] = sheetData;
            spreadsheet.push(sheet);
        });

        this.state = {
            data: spreadsheet,
            spreadsheet: spreadsheet,
            platformDisplayName,
            platformName,
            allPlatformsNames,
            platformDisplayNameSetting,
            numberOfMetrics,
            numbersOfDimensions,
            numberOfCalculatedFields,
            showMenu: false
        }
    }

    getMetricName = (metric) => {
        let metricName = replaceAll(metric, '_', ' ');
        if (metricName !== "key metrics" && metricName !== "key metric")
            metricName = replaceAll(metricName, "metrics", "");
        return toTitleCase(metricName.trim());
    };

    handleSearchChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const { spreadsheet } = this.state;
        let data = [];
        if (searchTerm === '')
            data = spreadsheet;
        else {
            spreadsheet.forEach((sheet) => {
                var newSheet = {};
                newSheet["properties"] = sheet["properties"];
                newSheet["data"] = [];
                sheet["data"].forEach((row) => {
                    if (row["fieldLabel"].toLowerCase().includes(searchTerm))
                        newSheet["data"].push(row);
                });
                data.push(newSheet);
            });
        }

        this.setState({ data });
    }

    showMenu = () => {
        const { showMenu } = this.state;
        this.setState({ showMenu: !showMenu });
    };

    render() {
        let {
            data,
            spreadsheet,
            platformDisplayName,
            platformName,
            allPlatformsNames,
            platformDisplayNameSetting,
            numberOfMetrics,
            numbersOfDimensions,
            numberOfCalculatedFields
        } = this.state;

        return (
            <Layout>
                <Helmet>
                    <title>{platformDisplayName} Metric and Dimension Full List | Nugit Data Storytelling</title>
                </Helmet>
                <div className="flex-container integration-supported-fields-container align-items-start">
                    <div class="hamburger-menu" onClick={this.showMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`flex w-20pc w-80pc-mobile mr-4 border-right ${this.state && this.state.showMenu ? "" : "integration-navigation-container"}`}>
                        <PlatformsNavigation allPlatforms={allPlatformsNames} platformDisplayNameSettings={platformDisplayNameSetting} currentPlatform={platformName}></PlatformsNavigation>
                    </div>
                    <div className="integration-supported-fields-grid-container">
                        <h1>{platformDisplayName}</h1>
                        <div className="flex-container flex-center mt-8 mb-8">
                            <div>
                                <div>
                                    <input className="search-box" type="text" placeholder="Search..." onChange={this.handleSearchChange} />
                                </div>
                            </div>
                            <span className="platform-summary ml-2">
                                {numberOfMetrics} metrics
                            </span>

                            <span className="platform-summary ml-2">
                                {numbersOfDimensions} dimensions
                            </span>
                            <span className="platform-summary ml-2">
                                {numberOfCalculatedFields} calculated fields
                            </span>
                        </div>
                        <div data-duration-in="300" data-duration-out="100" class="w-tabs">
                            <SheetsTab sheets={spreadsheet}></SheetsTab>
                            <SheetsGrid sheets={data}></SheetsGrid>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}