import React from "react";
import { resetWebflow } from "../../utils/common";
import Sheet from "./sheet";

export default class SheetsGrid extends React.Component {
    render() {
        resetWebflow("5aabd62c51e22e4f80346d1c");
        let { sheets } = this.props;
        return (
            <div class="tabs-main-content w-tab-content" >
                {sheets.map((sheet, idx) => (
                    <div data-w-tab={sheet.properties.title}
                        class={`w-tab-pane ${!idx ? "w--tab-active" : ""}`}>
                        <div class="w-embed">
                            <Sheet sheet={sheet} key={`${sheet.properties.title}-sheet-${idx}`}></Sheet>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
