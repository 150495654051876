import React from "react";
import sortIconImg from "../../../styles/images/sort-icon.svg";
import selectedSortIcon from "../../../styles/images/dropdown-icon-active.svg";

export default function SortIcon({ onClick, isASC }) {
  let isSelected = isASC !== undefined;
  isASC = isSelected && isASC;
  return (
    <a
      role="button"
      style={{
        cursor: "pointer",
        color: isSelected ? "#353c45" : ""
      }}
      onClick={onClick}
    >
      <img
        style={{ transform: isASC ? "rotateX(180deg)" : "" }}
        src={isSelected ? selectedSortIcon : sortIconImg}
        display="block"
        width="12"
        height="6"
      />
    </a>
  );
}
