import React from "react";

export default function SheetsTab({ sheets }) {
  sheets = sheets || [];
  return (
    <div className="tabs-side-menu w-tab-menu">
      {sheets.map(
        (sheet, idx) =>
          sheet.properties && (
            <a
              id={sheet.properties.sheetId}
              data-w-tab={sheet.properties.title}
              className={`tabs-menu side-menu w-inline-block w-tab-link ${!idx ? "w--current" : ""}`}
            >
              <div>{sheet.properties.title}</div>
            </a>
          )
      )}
    </div>
  );
}
