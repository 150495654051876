import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Pagination } from "../../utils/pagination";
import SortIcon from "./google-sheet/sortIcon";
import { getSafe } from "../../utils/common";

const ITEM_PER_PAGE = 50;

export default class Sheet extends Component {
    constructor(props) {
        super(props);
        let { sheet } = props;
        let pagination = new Pagination(sheet.data, ITEM_PER_PAGE);
        this.state = {
            pagination,
            sheets: sheet,
            data: pagination.pageChanged(1, false),
            forceSelected: 0,
            isSortByNameASC: false,
            isSortByTypeASC: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sheet === this.props.sheet)
            return;

        let { sheet } = this.props;
        let pagination = new Pagination(sheet.data, ITEM_PER_PAGE);
        this.setState({
            pagination,
            sheets: sheet,
            data: pagination.pageChanged(1, false),
            forceSelected: 0,
            isSortByNameASC: false,
            isSortByTypeASC: false
        });
    }

    renderRow(row) {
        return (
            <tr>
                <td data-label="Field Label (Nugit)" className="text-wrap-container">
                    <span class="data-name">{row.fieldLabel}</span>
                </td>
                <td data-label="Type" className="text-wrap-container">
                    <span className="text-capitalize data-type">{row.type}</span>
                    <br />
                    <span class="data-term" className="text-wrap-container">{row.outputFormat}</span>
                </td>
                <td data-label="Nugit Tables" className="text-wrap-container hide-on-mobile">{row.nugitTables}</td>
                <td data-label="Field Name (API)" className="text-wrap-container">{row.fieldName}</td>
                {/* <td data-label="Nugit Key" className="text-wrap-container">{row.nugitKey}</td> */}
                <td data-label="Combines With" className="text-wrap-container hide-on-mobile">{row.combinesWith}</td>
            </tr>
        );
    }

    handlePageClick = data => {
        let page = data.selected + 1;
        this.setState({
            forceSelected: page,
            data: this.state.pagination.pageChanged(page, false)
        });
    };

    sortByType() {
        const isASC = this.state.isSortByTypeASC;
        const data = this.state.sheets.data.sort((a, b) =>
            getSafe(() =>
                isASC ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type)
            )
        );
        let pagination = new Pagination(data, ITEM_PER_PAGE);
        this.setState({
            isSortByNameASC: this.state.isSortByNameASC,
            isSortByTypeASC: !isASC,
            pagination,
            data: pagination.pageChanged(1, false),
            sheets: this.state.sheets
        });
    }

    sortByName() {
        const isASC = this.state.isSortByNameASC;
        const data = this.state.sheets.data.sort((a, b) =>
            getSafe(() =>
                isASC ? a.fieldLabel.localeCompare(b.fieldLabel) : b.fieldLabel.localeCompare(a.fieldLabel)
            )
        );
        let pagination = new Pagination(data, ITEM_PER_PAGE);
        this.setState({
            isSortByNameASC: !isASC,
            isSortByTypeASC: this.state.isSortByTypeASC,
            pagination,
            data: pagination.pageChanged(1, false),
            sheets: this.state.sheets
        });
    }

    isSortByName = () => this.state && this.state.isSortByNameASC;

    isSortByType = () => this.state && this.state.isSortByTypeASC;

    render() {
        return (
            <React.Fragment>
                <table className="integration-grid-table">
                    <thead>
                        <tr>
                            <th scope="col" className="integration-field-label">
                                Field Label (Nugit)
                                <SortIcon onClick={() => this.sortByName()} isASC={this.isSortByName()}></SortIcon>
                            </th>
                            <th scope="col" className="integration-type">
                                <span>Type</span>
                                <SortIcon onClick={() => this.sortByType()} isASC={this.isSortByType()}></SortIcon>
                            </th>
                            <th scope="col" className="integration-tables hide-on-mobile">
                                Nugit Tables
                            </th>
                            <th scope="col" className="integration-field-name">
                                Field Name (API)
                            </th>
                            {/* <th scope="col" className="integration-nugit-key">
                                Nugit Key
                            </th> */}
                            <th scope="col" className="integration-combines-with hide-on-mobile">
                                Combines With
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state && this.state.data.map(row => this.renderRow(row))}
                    </tbody>
                </table>
                {this.state && this.state.pagination.isShowPagination && (
                    <React.Fragment>
                        <div class="md-spacing"></div>
                        <ReactPaginate
                            previousLabel={""}
                            nextLabel={""}
                            pageClassName={"page-number"}
                            breakClassName={"page-number"}
                            breakLabel={"..."}
                            pageCount={this.state.pagination.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}